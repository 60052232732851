@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --panelBgColor: #EFF2FB;
  --textcolor: #6a6a92;
  --textBlackColor: #333;
  --textPrimaryColor: #2323ce;
  --textSubPrimaryColor: #1285f5;
  --textPurpleColor: #701fb4;
  --whiteColor: #fff;
  --main-bg-color: brown;
  --font-Assistant: 'Assistant', sans-serif;
  --font-roboto-black: 'Roboto', sans-serif;
  --font-roboto-regular: 'Roboto', sans-serif;
}
@font-face {
  font-family: 'Assistant';
  src: url(../../src/Assests/Fonts/Assistant-VariableFont_wght.ttf);
}
@font-face {
  font-family: 'Roboto-Black';
  src: url(../../src/Assests/Fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url(../../src/Assests/Fonts/Roboto-Regular.ttf);
}


*{
  font-family: var(--font-Assistant);
  /* margin: 0;
padding: 0; */
img{
  width: 100%;
  height: auto;
}
}
.HR_MainHeaderSection{
  width: auto;
  /* margin-bottom: 2vw; */
}
.HR_panel{
  background-color: var(--panelBgColor);
  padding: 1vw 5vw;
}
.HR_FlexCenterSpcBetween{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainpanel{
    background-color: #f8faff;
    padding: 2vw 6vw;
}
.Leftcontainer{
    width: 50%;
}
.Rightcontainer{
    width: 50%;
}
.Flex{
    display: flex;
}
.mainheading{
    font-family: "montserrat";
    font-weight: 800;
    color: #2323ce;
    font-size: 3vw;
    text-align: left;
}
.mainheading2{
    font-family: "montserrat";
    font-weight: 500;
    color: #2323ce;
    font-size: 2.7vw;
}
.secondContainer{
    font-family: var(--font-roboto-black);
    font-size: 2.9vw;
    font-weight: 900;
    text-align: left;
    color: #2323ce;
}
.pera{
    font-family: var(--font-Assistant);
    font-size: 1.25vw;
    text-align: left;
    color: #6a6a92;
}
li {
    list-style: none;
    padding-left: 30px; 
  }
  
li::before {
    content: "\2713"; /* Unicode for the tick mark */
    display: inline-block;
    width: 20px; /* Adjust the width and height as needed */
    text-align: center;
    margin-left: -30px; /* Negative margin to overlap with the text */
  }
  .removetick li::before {
    content: none;
  }
  .subheading{
    text-align: center;
    font-size: 3.5vw;
    font-weight: 800;
    color: #2323ce;
  }
  .subpera{
    text-align: center;
    font-size: 1.5vw;
    color: var(--textcolor);
  }
  .days{
    color: #2323ce;
  }
  .cardborder{
    border: 1px solid #333;
    border-radius: 0.54vw;
  }
  .cardwidth{
    width: 30%;
  }
  .imgsize{
    width: 7vw;
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
  }
  
  .card {
    width: calc(33.33% - 1vw); 
    margin-bottom: 2vw; 
  }
  
  .first-row {
    width: calc(33.33% - 1vw);
  }
  .description{
    text-align: left;
    font-size: 1.25vw;
  }
  .description1{
    text-align: left;
    font-size: 1.25vw;
    color: #fff;
    margin: 0;
  }
  .Availailty{
        width: calc(50% - 10px); /* Adjust width and margins as needed */
        margin-bottom: 20px; /* Adjust margin as needed */
        border-radius: 0.54vw;
        background-color: #ffffff ;
        border: 1px solid #f5f5f5;
  }
  .imgsizeA{
    width: 4vw;
    align-items: center;
  }
  .microsoftlogo{
    display: flex;
    align-items: center;
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: 2.5vw;
    color: #2323ce;

    border-radius: 100%;
   
  }
  .microsoftlogo1{
    display: flex;
    align-items: center;
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: 2.5vw;
    color: #ffff;

    border-radius: 100%;
   
  }
  .heading{
    font-size: 2vw;
    text-align: left;
  }
  .heading1{
    font-size: 2vw;
    text-align: left;
    color: #fff;
    margin: 0;
  }
  .heading2{
    font-size: 3vw;
    text-align: center;
    color: #fff;
    margin-bottom: 1vw;
  }
  .Availailty2{
    width: 100%;
        margin-bottom: 20px; /* Adjust margin as needed */
        border-radius: 0.54vw;
        background-color: #ffffff ;
        border: 1px solid #f5f5f5;
        margin-right: 10px;
        padding: 2vw;
  }
  .card-container1 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    background: linear-gradient(to Top, #1285f5, #2323ce);
    border-radius: 1.5vw;
    padding: 2vw;
  }
  .Availailty3{
    width: calc(50% - 10px); /* Adjust width and margins as needed */
    margin-bottom: 20px; /* Adjust margin as needed */
}
.Availailty4{
    width: 100%;
    margin-bottom: 20px; 
}
.GCCImage{
    width: 4.5vw;
    color: #ffffff;
}
.HR_planprice{
    /* color: black; */
    font-weight: 650;
    font-size: 2vw;
    font-family: var(--font-Assistant);
  }
  .text{
    font-size: 0.90vw;
    font-family: var(--font-Assistant);
  }
   .Ptext{
    font-size: 1.25vw;
    /* color: var(--textBlackColor); */
    font-family: var(--font-Assistant);
    margin-top: 1vw;
    margin-bottom: 1vw;
  
  }
  .features {
    list-style: none;
    padding-bottom: 4vw;
    padding-left: 2vw;
    text-align: left;
    font-size: 1vw;
    font-family: var(--font-Assistant);
  }
  .Hr_flex{
    margin-top: 2vw;
  }
  .support{
    border: 1px solid #2323ce;
    padding: 1vw ;
    border-radius: 0.54vw;
    max-width: 35%;
  }
  .Supportheading{
    font-size: 2vw;
    color: #2323ce;
  }
  .Supportdescription{
    font-size: 1.25vw;
    text-align: left;
    color: #6a6a92;
  }
  .supportmain{
    display: flex;
    justify-content: center;
    gap: 1vw;
    margin-bottom: 5vw;
  }
  .section2{
    font-size: 3vw;
    text-align: left;
    color: #2323ce;
    font-weight: 900;
    margin: 2vw;
  }
.plansheading{
    font-size: 3vw;
    font-weight: 800;
    color: #2323ce;
}
.planshead{
    font-size: 2vw;
    color: #2323ce;
}
.slider-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  
  .slider {
    display: flex;
    /* overflow-x: hidden; */
  }
  
  .slide {
    flex: 0 0 20%; /* Adjust the width to display 5 logos at a time */
    height: 100px; /* Set the height of the slide */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 24px;
    color: #000;
    cursor: pointer;
    outline: none;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .active {
    display: block;
  }
  
  .inactive {
    display: none;
  }
  /* .HR_MT{
    border: 1px solid red;

  } */
  .SliderCont{
    background-color: #2323ce;
    margin: -5vw auto auto auto;
  }
  @media only screen and (max-width: 768px){
    .Flex{
        display: block;
    }
    .first-row {
        width:100%;
      }
     .imgsize{
        width: 20vw;
      }
      .description{
        font-size: 6vw;
      }
      .card{
        width: 100%;
      }
      .Leftcontainer{
        width: 100%;
    }
    .Rightcontainer{
        width: 100%;
    }
    .mainheading{
        font-size: 6vw;
    }
    .mainheading2{
        font-size: 5vw;
    }
    .secondContainer{
        font-size: 6vw;
        text-align: center;
    }
    .pera{
        font-size: 5vw;
        text-align: center;
    }
    .secondContainer{
        text-align: left;
    }
    .subheading{
        font-size: 5vw;
    }
    .subpera{
        font-size: 4vw;
    }
    .button{
        font-size: 5vw;
    }
    .section2{
        font-size: 8vw;
    }
    .microsoftlogo{
        font-size: 20vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .heading{
        font-size: 6vw;
        text-align: center;
    }
    .HR_FlexCenterSpcBetween{
      display: block;
    }
    .card-container{
        display: block;
    }
    .Availailty{
        width: 100%;
    }
    .card-container1{
        display: block;
    }
    .Availailty3{
        width: 100%;
    }
    .heading2{
        font-size: 5vw;
    }
    .description1{
        font-size: 4vw;
        text-align: center;
    }
    .GCCImage{
        width: 13vw;
    }
    .microsoftlogo1{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .heading1{
        font-size: 5vw;
        text-align: center;
    }
    .plansheading{
        font-size: 6vw;
    }
    .planshead{
        font-size: 4.5vw;
    }
    .features{
        font-size: 6vw;
    }
    .HR_planprice{
        font-size: 7vw;
        margin: unset;
    }
    .text{
        font-size: 4vw;
    }
    .Ptext{
        font-size: 4.5vw;
    }
    .plan{
        padding: 2vw;
    }
    .supportmain {
        display: block;
    }
    .support{
        max-width: 100%;
        margin-bottom: 10px;
        padding: 1vw;
    }
    .Supportheading{
        font-size: 5vw;
    }
    .Supportdescription{
        font-size: 4vw;
    }
  }
